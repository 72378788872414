bcl.c.mybContent = {
  props: {
    className: "c-myb-content",
    classClientName: "clientName-JS",
    classClientNumber: "clientNumber-JS",
    classMybStays: "mybStays-JS",
    classMybDiscount: "mybDiscount-JS",
    wildCardName: "[name]",
    wildCardNumber: "[clientCode]",
    wildCardStays: "[mybStays]",
    wildCardDiscount: "[mybDiscount]",
    localStorageKey: "userInfo",
  },

  init: function (baseDom) {
    const baseFind = baseDom || document;
    const components = baseFind.getElementsByClassName(this.props.className);
    if (!components.length) {
      return;
    }

    Array.from(components).forEach((element) => this.getInfo(element));
  },

  getInfo: function (component) {
    this.updateInfo(component);
  },

  updateDateEditMode: function (component) {
    const editPlaceholders = {
      [this.props.classClientName]: { wildcard: this.props.wildCardName, value: "[name]" },
      [this.props.classClientNumber]: { wildcard: this.props.wildCardNumber, value: "[clientCode]" },
      [this.props.classMybStays]: { wildcard: this.props.wildCardStays, value: "[mybStays]" },
      [this.props.classMybDiscount]: { wildcard: this.props.wildCardDiscount, value: "[mybDiscount]" },
    };
    this.replaceWildCards(component, editPlaceholders);
  },

  updateDateWithoutData: function (component) {
    if (!bcl.profile.mapValues.data) {
      this.replaceWildCards(component, {
        [this.props.classClientName]: { wildcard: this.props.wildCardName, value: "" },
        [this.props.classClientNumber]: { wildcard: this.props.wildCardNumber, value: "" },
        [this.props.classMybStays]: { wildcard: this.props.wildCardStays, value: "" },
        [this.props.classMybDiscount]: { wildcard: this.props.wildCardDiscount, value: "" },
      });
    }
  },

  updateInfo: function (component) {
    const userData = bcl.profile.mapValues.data || {};
    const { level = 0, name = "", identifier = "", bookingNumber = "", discount = "" } = userData;

    this.applyLevelClass(component, level);
    this.replaceWildCards(component, {
      [this.props.classClientName]: { wildcard: this.props.wildCardName, value: name },
      [this.props.classClientNumber]: { wildcard: this.props.wildCardNumber, value: identifier },
      [this.props.classMybStays]: { wildcard: this.props.wildCardStays, value: bookingNumber },
      [this.props.classMybDiscount]: { wildcard: this.props.wildCardDiscount, value: discount },
    });
  },

  applyLevelClass: function (component, level) {
    const levelClasses = ["mod--standard", "mod--intense", "mod--unique"];
    component.classList.add(levelClasses[Math.min(level, levelClasses.length - 1)]);
  },

  replaceWildCards: function (component, replacements) {
    for (const [className, { wildcard, value }] of Object.entries(replacements)) {
      const elements = component.getElementsByClassName(className);
      Array.from(elements).forEach((element) => {
        const text = element.dataset.text || "";
        element.innerHTML = text.replace(wildcard, value || "");
      });
    }
  },
};
