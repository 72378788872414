/* eslint-disable no-useless-escape */
bcl.c.mybEditProfileV2 = {
  personalElements: {
    form: ".js-form-personal-data",
    name: ".js-input-name",
    surname: ".js-input-surname",
    birthday: ".js-input-birthday",
    phone: ".js-input-phone",
    phonePrefixParent: ".js-phone-parent",
    phonePrefix: ".iti__active",
    address: ".js-input-address",
    postalCode: ".js-input-postal-code",
    province: ".js-input-province",
    city: ".js-input-city",
    country: ".js-select-country",
    countryFlag: ".js-change-flag",
    email: ".js-input-email",
    accordionButtons: ".js-open-accordion",
  },

  modals: {
    activeClass: "tingle-modal--visible",
    successModal: ".js-success-modal",
    errorModal: ".js-error-modal",
  },

  props: {
    component: "c-myb-edit-profile-content-v2",
    endpointPostData: "",
    endpointPost: "",
    invalidInput: ".mod--invalidate",
    updateButton: ".js-update-button",
    info: {
      givenName: "",
      surname: "",
      phoneNumber: "",
      phoneCountryPrefix: "",
      address: "",
      city: "",
      postalCode: "",
      state: "",
      country: "",
      birthdayDate: "",
      email: "",
    },
    inputs: {},
  },

  preInit() {
    bcl.u.docReady(this.init.bind(this));
  },

  init(baseDom) {
    const base = baseDom || document;
    const component = base.querySelector(`.${this.props.component}`);

    if (!component) {
      return;
    }

    this.props.endpointPostData = component.dataset.urlPostData;
    this.props.endpointPost = component.dataset.urlPost;
    this.setInputs(component);
    this.addListenersWithDelay(component);
  },

  addListenersWithDelay(element) {
    setTimeout(() => {
      this.addListeners(element);
    }, 250);
  },

  addListeners(element) {
    const form = element.querySelector(this.personalElements.form);
    const updateButton = element.querySelector(this.props.updateButton);
    const inputSelectors = ["name", "surname", "birthday", "phone", "address", "postalCode", "province", "city", "country"];

    const inputs = inputSelectors
      .map((key) => ({
        key,
        element: element.querySelector(this.personalElements[key]),
      }))
      .filter((item) => item.element);

    const originalValues = inputs.map((item) => item.element.value);

    const checkForChanges = () => {
      const hasChanges = inputs.some((item, index) => item.element.value !== originalValues[index]);
      updateButton.classList.toggle("mod--inactive", !hasChanges);
    };

    if (form) {
      form.addEventListener("submit", this.submitPersonalForm.bind(this));
    }

    inputs.forEach((item) => {
      item.element.addEventListener("change", () => {
        this.validateInput(item.element, form);
        checkForChanges();
        if (item.key === "country") {
          this.updateCountryFlag(element, item.element.value);
        }
      });
    });
  },

  updateCountryFlag(element, countryValue) {
    const flagContainer = element.querySelector(this.personalElements.countryFlag);
    if (flagContainer) {
      flagContainer.innerHTML = `<span class='iti__flag iti__${countryValue.toLowerCase()}'></span>`;
    }
  },

  validateInput(input, form) {
    const invalidPattern = /[`!¡¿¬¨@#$%^&*()_+\=\[\]{};':"\\|<>\/?~€]/;
    const invalidStart = /^[\s\-,.]/;

    const isInvalid = invalidPattern.test(input.value) || invalidStart.test(input.value);
    form.classList.toggle("errorValidationForm", isInvalid);
    input.classList.toggle("mod--invalidate", isInvalid);
  },

  submitPersonalForm(event) {
    event.preventDefault();
    const form = event.target;
    if (form.querySelector(this.props.invalidInput)) {
      return;
    }

    const fieldsToValidate = ["name", "surname", "address", "postalCode", "province", "city", "country", "email", "birthday", "phone"];

    fieldsToValidate.forEach((key) => {
      const input = this.props.inputs[key];
      if (input) {
        this.validateInput(input, form);
        this.props.info[this.mapInfoKey(key)] = key === "country" ? input.value.toLowerCase() : input.value;
      }
    });

    const phonePrefix = this.props.inputs.phonePrefix.querySelector(this.personalElements.phonePrefix);
    if (phonePrefix) {
      this.props.info.phoneCountryPrefix = phonePrefix.dataset.dialCode || "";
    }

    if (!form.classList.contains("errorValidationForm")) {
      this.updateApiData(form);
    } else {
      this.handleSubmitError(form);
    }
  },

  mapInfoKey(key) {
    const mapping = {
      name: "givenName",
      email: "email",
      phone: "phoneNumber",
      province: "state",
    };
    return mapping[key] || key;
  },

  setValueKey(key, value) {
    if (key && value) {
      this.props.info[key] = value;
    }
  },

  updateApiData(form) {
    bcl.ajax.postRequest({
      data: this.props.info,
      url: this.props.endpointPost,
      callback: (e) => this.handleSubmitSuccess(e, form),
      errorCallback: (e) => this.handleSubmitError(e, form),
    });
  },

  getApiData(form) {
    bcl.ajax.postRequest({
      data: this.props.info,
      url: this.props.endpointPostData,
      callback: (e) => this.setApiData(JSON.parse(e.responseText), form),
      errorCallback: (e) => this.handleSubmitError(e, form),
    });
  },

  setApiData(data, form) {
    this.props.data = data;
    const fields = {
      name: data.givenName || "",
      surname: data.surname || "",
      birthday: data.birthdayDate && data.birthdayDate !== "1900-01-01" ? data.birthdayDate : "",
      phone: data.phoneNumber && data.phoneNumber !== "000000000" ? data.phoneNumber : "",
      email: data.email || "",
      address: data.address || "",
      postalCode: data.postalCode || "",
      province: data.state || "",
      city: data.city || "",
      country: data.country ? data.country.toUpperCase() : "",
    };

    Object.keys(fields).forEach((key) => {
      if (this.props.inputs[key]) {
        this.props.inputs[key].value = fields[key];
      }
    });

    if (data.birthdayDate && this.props.inputs.birthday) {
      const [input1, input2] = this.props.inputs.birthday;
      input1.value = data.birthdayDate;
      if (input2) {
        input2.value = data.birthdayDate.split("-").reverse().join("/");
      }
    }

    if (data.country && form) {
      this.updateCountryFlag(form, data.country.toLowerCase());
    }

    setTimeout(this.selectedFlag.bind(this), 300);
  },

  selectedFlag() {
    const prefix = this.props.inputs.phonePrefix.querySelector(`.iti__country.iti__standard[data-dial-code="${this.props.data.phoneCountryPrefix}"]`);
    const selected = this.props.inputs.phonePrefix.querySelector(".iti__selected-flag");
    if (prefix && selected) {
      const newFlag = prefix.querySelector(".iti__flag");
      const newName = prefix.querySelector(".iti__country-name");
      const dialCode = prefix.querySelector(".iti__dial-code");
      const selectedFlag = selected.firstElementChild;

      if (newFlag && newName && dialCode && selectedFlag) {
        selected.title = `${newName.textContent}: ${dialCode.textContent}`;
        selectedFlag.className = newFlag.className;
      }
    }
  },

  setInputs(form) {
    this.props.inputs = {
      form,
      name: form.querySelector(this.personalElements.name),
      surname: form.querySelector(this.personalElements.surname),
      birthday: form.querySelectorAll(this.personalElements.birthday),
      phone: form.querySelector(this.personalElements.phone),
      phonePrefix: form.querySelector(this.personalElements.phonePrefixParent),
      email: form.querySelector(this.personalElements.email),
      address: form.querySelector(this.personalElements.address),
      postalCode: form.querySelector(this.personalElements.postalCode),
      province: form.querySelector(this.personalElements.province),
      city: form.querySelector(this.personalElements.city),
      country: form.querySelector(this.personalElements.country),
    };
    this.getApiData(form);
  },

  handleSubmitSuccess(event, form) {
    const successModal = form.querySelector(this.modals.successModal);
    const closeModal = successModal.querySelector(".js-close-modal");

    const closeHandler = () => {
      successModal.classList.remove(this.modals.activeClass);
      closeModal.removeEventListener("click", closeHandler);
    };

    closeModal.addEventListener("click", closeHandler);
    successModal.classList.add(this.modals.activeClass);
  },

  handleSubmitError(event, form) {
    const errorModal = form.querySelector(this.modals.errorModal);
    const closeModal = errorModal.querySelector(".js-close-modal");

    const closeHandler = () => {
      errorModal.classList.remove(this.modals.activeClass);
      closeModal.removeEventListener("click", closeHandler);
    };

    closeModal.addEventListener("click", closeHandler);
    errorModal.classList.add(this.modals.activeClass);
  },
};

bcl.c.mybEditProfileV2.preInit();
