const baseColor = "#f5f5f5";

bcl.map.props.mapStyles = {
  defaultStyle: [
    {
      elementType: "geometry",
      stylers: [{ color: baseColor }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#616161" }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: baseColor }],
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [{ color: "#bdbdbd" }],
    },
    {
      featureType: "landscape",
      elementType: "geometry.stroke",
      stylers: [{ color: "#5d5d5d" }],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "#757575" }],
    },
    {
      featureType: "poi.park",
      stylers: [
        { elementType: "geometry", color: "#e5e5e5" },
        { elementType: "labels.text.fill", color: "#9e9e9e" },
      ],
    },
    {
      featureType: "road",
      stylers: [
        { elementType: "geometry", color: "#ffffff" },
        { elementType: "geometry.stroke", lightness: 20 },
        { elementType: "labels.text.fill", color: "#9e9e9e" },
      ],
    },
    {
      featureType: "road.arterial",
      stylers: [
        { elementType: "geometry.fill", color: "#e4e4e4" },
        { elementType: "labels.text.fill", color: "#757575" },
      ],
    },
    {
      featureType: "road.highway",
      stylers: [
        { elementType: "geometry", color: "#dadada" },
        { elementType: "labels.text.fill", color: "#616161" },
      ],
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [{ color: "#e5e5e5" }],
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [{ color: "#eeeeee" }],
    },
    {
      featureType: "water",
      stylers: [
        { elementType: "geometry", color: "#c9c9c9" },
        { elementType: "geometry.fill", color: "#c6e8f2" },
        { elementType: "labels.text.fill", color: "#9e9e9e" },
      ],
    },
  ],
};
