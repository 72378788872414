bcl.map = {
  props: {
    readyState: false,
    nameInitEvent: "barcelo-initMap",
    nameAttr: "data-map-id",
    url: "https://maps.googleapis.com/maps/api/js",
    loaded: false,
  },

  init(baseDom = document) {
    if (this.props.loaded) {
      return this.loaded();
    }

    if (window.googleApiKey) {
      const elements = baseDom.querySelectorAll(this.getSelectorAttr());

      if (elements.length && !this.props.readyState) {
        this.props.readyState = true;
        bcl.u.loadScript({
          src: `${this.props.url}?key=${window.googleApiKey}`,
          type: "text/javascript",
          callback: this.loaded,
          defer: true,
        });
      }
    }
  },

  getSelectorAttr(value = "") {
    return `[${this.props.nameAttr}${value ? `="${value}"` : ""}]`;
  },

  loaded() {
    this.props.loaded = true;
    document.dispatchEvent(new Event(this.props.nameInitEvent));
  },

  ready(callback) {
    this.props.readyState ? callback() : document.addEventListener(this.props.nameInitEvent, callback);
  },

  smoothZoom(map, targetZoom, currentZoom = map.getZoom()) {
    if (currentZoom !== targetZoom) {
      window.google.maps.event.addListenerOnce(map, "zoom_changed", () => this.smoothZoom(map, targetZoom, currentZoom + (targetZoom > currentZoom ? 1 : -1)));
      setTimeout(() => map.setZoom(currentZoom), 80);
    }
  },

  popupClass() {
    /**
     * Customized popup on the map.
     * @param {!google.maps.LatLng} position
     * @param {!Element} content The bubble div.
     * @constructor
     * @extends {google.maps.OverlayView}
     */
    const Popup = function (position, content) {
      this.position = position;

      bcl.u.addClass(content, "popup-bubble");
      const bubbleAnchor = document.createElement("div");
      bcl.u.addClass(bubbleAnchor, "popup-bubble-anchor");
      bubbleAnchor.appendChild(content);

      this.containerDiv = document.createElement("div");
      bcl.u.addClass(this.containerDiv, "popup-container");
      this.containerDiv.appendChild(bubbleAnchor);

      if (content.dataset.popupCallback) {
        bcl.u.executeFunctionByName(content.dataset.popupCallback, window, content);
      }

      window.google.maps.OverlayView.preventMapHitsAndGesturesFrom(this.containerDiv);
    };

    Popup.prototype = Object.create(window.google.maps.OverlayView.prototype);

    Popup.prototype.onAdd = function () {
      this.getPanes().floatPane.appendChild(this.containerDiv);
    };

    Popup.prototype.onRemove = function () {
      this.containerDiv.parentElement?.removeChild(this.containerDiv);
    };

    Popup.prototype.draw = function () {
      const divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
      const display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? "block" : "none";

      if (display === "block") {
        this.containerDiv.style.left = `${divPosition.x}px`;
        this.containerDiv.style.top = `${divPosition.y}px`;
      }
      if (this.containerDiv.style.display !== display) {
        this.containerDiv.style.display = display;
      }
    };

    return Popup;
  },
};
