bcl.c.mybProfileStatus = {
  props: {
    className: "c-myb-profile-status",
    classNameHeader: ".c-myb-profile-status__header",
    classNameMilestone: "c-progress-bar__milestone",
    classActive: "mod--active",
    classLastActive: "mod--last-active",
  },

  init: function () {
    const components = document.getElementsByClassName(this.props.className);
    if (!components.length) {
      return;
    }

    Array.from(components).forEach((element) => {
      const headerNode = element.querySelector(this.props.classNameHeader);
      if (headerNode) {
        this.setData(element, headerNode);
      }
    });
  },

  setData: function (component, headerNode) {
    this.update(component, headerNode);
  },

  update: function (component, headerNode) {
    bcl.profile.parsePlaceholderInContainer(headerNode);

    const bookingNumber = bcl.profile.mapValues.data?.bookingNumber || 0;
    bcl.s.progressBar.updateClass(bookingNumber - 1, component);
  },
};
