/* eslint-disable @typescript-eslint/no-use-before-define */
(() => {
  const MyBHero = () => {
    const selectors = {
      component: "myb-hero",
      swiperCarousel: ".cmp-bhg-swiper-js",
      animationCards: ".js-myb-animation-cards",
      animationCardsCard: ".js-animation-cards-card",
    };

    let firstLoad = true;

    const init = () => {
      const parentElements = Array.from(document.getElementsByClassName(selectors.component));
      if (!parentElements.length) {
        return;
      }

      window.addEventListener("load", () => {
        parentElements.forEach((element) => {
          initializeSlides(element);
          executeAnimationCards(element);
          activateCarousel(element);
        });
      });
    };

    const initializeSlides = (element) => {
      const slides = element.querySelectorAll(".swiper-slide");

      slides.forEach((slide) => {
        window.addEventListener("resize", () => {
          if (window.innerWidth > 1024) {
            activateCarousel(element);
          }
        });

        const slideNumber = document.createElement("span");
        slideNumber.classList.add("number-slide");
        const index = parseInt(slide.getAttribute("data-swiper-slide-index"), 10) + 1;
        slideNumber.innerHTML = index < 10 ? `0${index}` : index;
        slide.appendChild(slideNumber);
      });
    };

    const activateCarousel = (element) => {
      const carousel1 = element.querySelector(".js-main-carousel");
      const carousel2 = element.querySelector(".js-second-carousel");

      if (!carousel1 || !carousel2) {
        return;
      }

      const swiperCarousel1 = carousel1.querySelector(selectors.swiperCarousel)?.swiper;
      const swiperCarousel2 = carousel2.querySelector(selectors.swiperCarousel)?.swiper;
      if (!swiperCarousel1 || !swiperCarousel2) {
        return;
      }

      swiperCarousel2.allowTouchMove = false;

      if (firstLoad) {
        synchronizeCarousels(swiperCarousel1, swiperCarousel2);
        firstLoad = false;
      }

      swiperCarousel1.on("transitionEnd", () => {
        handleCarouselTransition(swiperCarousel1, swiperCarousel2);
      });
    };

    const synchronizeCarousels = (swiper1, swiper2) => {
      const adjustedIndex = (swiper1.realIndex - 1 + swiper1.slides.length) % swiper1.slides.length;
      swiper2.slideToLoop(adjustedIndex, 0);
      updateSlideOpacity(swiper2);
    };

    const handleCarouselTransition = (swiper1, swiper2) => {
      updateSlideOpacity(swiper2, 0);
      const adjustedIndex = (swiper1.realIndex - 1 + swiper1.slides.length) % swiper1.slides.length;
      swiper2.slideToLoop(adjustedIndex, 0);

      setTimeout(() => updateSlideOpacity(swiper2, 1), 200);
    };

    const updateSlideOpacity = (swiper, opacity = 1) => {
      swiper.slides.forEach((slide, index) => {
        slide.style.transition = "opacity 1s ease-in-out";
        slide.style.opacity = index === swiper.realIndex ? opacity : 0;
      });
    };

    const executeAnimationCards = (element) => {
      const mainCarousel = element.querySelector(".js-main-carousel .cmp-bhg-swiper-js")?.swiper;
      if (!mainCarousel) {
        return;
      }

      const animationCards = element.querySelectorAll(selectors.animationCards);
      animationCards.forEach((animation) => {
        if (!animation.classList.contains("mod--edit")) {
          displayCardsInOrder(animation);
          mainCarousel.on("slideChange", () => {
            setTimeout(() => displayCardsInOrder(animation), 200);
          });
        }
      });
    };

    const displayCardsInOrder = (animationCardsContainer) => {
      const cards = animationCardsContainer.querySelectorAll(selectors.animationCardsCard);
      const isActiveSlide = animationCardsContainer.closest(".swiper-slide")?.classList.contains("swiper-slide-active");
      let delay = 0;

      cards.forEach((card) => {
        setTimeout(
          () => {
            card.classList.toggle("mod--display-card", isActiveSlide);
          },
          isActiveSlide ? delay : 0,
        );
        delay += isActiveSlide ? 1000 : 0;
      });
    };

    init();
  };

  document.addEventListener("DOMContentLoaded", MyBHero);
})();
