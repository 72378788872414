bcl.c.mybBanner = {
  props: {
    componentSelector: ".c-myb-banner-JS",
    bannerSelector: "div[data-loyalty-level]",
    hiddenClass: "hiddenVisual",
  },

  init: function (userData) {
    const loyaltyLevel = parseInt(userData?.federationId ? userData["myb-level"] : 0);

    document.querySelectorAll(this.props.componentSelector).forEach(($component) => {
      $component.querySelectorAll(this.props.bannerSelector).forEach(($banner) => {
        parseInt($banner.dataset.loyaltyLevel) === loyaltyLevel ? $banner.classList.remove(this.props.hiddenClass) : $banner.remove();
      });
    });
  },
};
