(() => {
  const MybFeatures2 = () => {
    const selectors = {
      component: ".myb-features-2",
      content: ".js-myb-features-2",
      tabItem: ".js-myb-features-2-tab-item",
      headerLevel: ".js-myb-features-2-header-level",
      rowSection: ".js-myb-features-2-row-section",
      accordion: ".js-myb-features-2-accordion",
    };

    const cleanTab = (content, tabItems) => {
      content.classList.remove("mod--essential", "mod--special", "mod--unique");
      tabItems.forEach((item) => item.classList.remove("mod--active"));
    };

    const setHoverClass = (content, levelClass) => {
      if (content.dataset.accordionClose !== "true") {
        content.classList.remove("mod--essential-hover", "mod--special-hover", "mod--unique-hover");
        if (levelClass) {
          content.classList.add(levelClass);
        }
      }
    };

    const updateAccordionState = (content) => {
      const activeAccordions = content.querySelectorAll(`${selectors.accordion}.active`);
      if (activeAccordions.length === 0) {
        content.dataset.accordionClose = "true";
        setHoverClass(content, null);
      } else {
        content.removeAttribute("data-accordion-close");
        content.classList.toggle("mod--one-accordion", activeAccordions.length === 1);
      }
    };

    const init = () => {
      const elements = Array.from(document.querySelectorAll(selectors.component));
      elements.forEach((element) => {
        const content = element.querySelector(selectors.content);
        const tabItems = element.querySelectorAll(selectors.tabItem);
        const headerLevels = element.querySelectorAll(selectors.headerLevel);
        const rowSections = element.querySelectorAll(selectors.rowSection);
        const accordions = element.querySelectorAll(selectors.accordion);

        content.addEventListener("mouseleave", () => {
          setHoverClass(content, null);
        });

        tabItems.forEach((item) => {
          item.addEventListener("click", () => {
            cleanTab(content, tabItems);
            const typeClass = `mod--${item.dataset.type}`;
            content.classList.add(typeClass);
            item.classList.add("mod--active");
          });
        });

        const handleMouseEnter = (section) => {
          if (section.classList.contains("--essential")) {
            setHoverClass(content, "mod--essential-hover");
          } else if (section.classList.contains("--special")) {
            setHoverClass(content, "mod--special-hover");
          } else if (section.classList.contains("--unique")) {
            setHoverClass(content, "mod--unique-hover");
          }
        };

        headerLevels.forEach((level) => {
          level.addEventListener("mouseenter", () => handleMouseEnter(level));
        });

        rowSections.forEach((section) => {
          section.addEventListener("mouseenter", () => handleMouseEnter(section));
        });

        accordions.forEach((accordion) => {
          accordion.addEventListener("click", () => {
            accordion.classList.toggle("active");
            updateAccordionState(content);
          });
        });
      });
    };

    init();
  };

  window.addEventListener("load", MybFeatures2);
})();
