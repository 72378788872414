(() => {
  const MybBannerAnimated = () => {
    const selectors = {
      component: ".myb-banner-animated",
      content: ".js-banner-animated",
      bannerImage: ".js-banner-animated-image",
      bannerImageContent: ".cmp-image__image",
    };

    const scrollHandler = (content) => {
      const inView = content.getBoundingClientRect().y > -20 && content.getBoundingClientRect().y < window.innerHeight - 100;
      if (window.innerWidth < 1025) {
        content.classList.toggle("mod--number", inView);
      } else {
        content.classList.remove("mod--number");
      }
    };

    const initBannerImageMask = (bannerImage, bannerImageContent) => {
      if (bannerImageContent) {
        bannerImage.style.maskImage = `url('${bannerImageContent.src}')`;
      }
    };

    const init = () => {
      const elements = Array.from(document.querySelectorAll(selectors.component));

      elements.forEach((element) => {
        const content = element.querySelector(selectors.content);
        const bannerImage = element.querySelector(selectors.bannerImage);
        const bannerImageContent = bannerImage.querySelector(selectors.bannerImageContent);

        initBannerImageMask(bannerImage, bannerImageContent);
        scrollHandler(content);

        window.addEventListener("resize", () => scrollHandler(content));
        window.addEventListener("scroll", () => scrollHandler(content));
      });
    };

    init();
  };

  window.addEventListener("load", MybBannerAnimated);
})();
