bcl.c.mybPreferences = {
  personalElements: {
    form: ".js-preference-form",
    currency: ".js-input-currency",
    language: ".js-input-language",
    preferences: ".js-search-items",
    preferenceItem: ".js-search-item",
    countryFlag: ".js-change-flag",
  },

  modals: {
    activeClass: "tingle-modal--visible",
    successModal: ".js-success-modal",
    errorModal: ".js-error-modal",
  },

  props: {
    component: "c-myb-preferences",
    updateButton: ".js-save-preferences",
    info: { language: "string", currency: "string", preferences: "array" },
    inputs: {},
    preferencesData: [],
  },

  preInit: function () {
    bcl.u.docReady(this.init.bind(this));
  },

  init: function (baseDom = document) {
    const component = baseDom.querySelector(`.${this.props.component}`);
    if (!component) {
      return;
    }

    this.props.endpointPostData = component.dataset.urlPostData;
    this.props.endpointPost = component.dataset.urlPost;
    this.setInputs(component);

    setTimeout(() => {
      this.addListeners(component);
    }, 500);
  },

  addListeners: function (element) {
    const form = element.querySelector(this.personalElements.form);
    form?.addEventListener("submit", this.submitPersonalForm.bind(this));

    const languageInput = element.querySelector(this.personalElements.language);
    const currencyInput = element.querySelector(this.personalElements.currency);

    languageInput?.addEventListener("click", () => languageInput.classList.toggle("mod--open"));
    languageInput?.addEventListener("change", () => this.updateCountryFlag(languageInput.value, element));

    currencyInput?.addEventListener("click", () => currencyInput.classList.toggle("mod--open"));

    element.querySelectorAll(this.personalElements.preferenceItem).forEach((item) => {
      item.addEventListener("click", () => {
        const isActive = item.classList.toggle("mod--active");
        this.props.preferencesData[item.dataset.value] = isActive;
      });
    });
  },

  submitPersonalForm: function (event) {
    event.preventDefault();
    const form = event.target;

    if (!form.querySelector(this.props.invalidInput) && !form.classList.contains("errorValidationForm")) {
      this.props.info.language = this.props.inputs.language?.value;
      this.props.info.currency = this.props.inputs.currency?.value;
      this.props.info.preferences = this.props.preferencesData;

      this.updateApiData(form);
    } else {
      this.errorSubmit(null, form);
    }
  },

  updateApiData: function (form) {
    bcl.ajax.postRequest({
      data: this.props.info,
      url: this.props.endpointPost,
      callback: (e) => this.successSubmit(e, form),
      errorCallback: (e) => this.errorSubmit(e, form),
    });
  },

  getApiData: function (form) {
    bcl.ajax.postRequest({
      data: this.props.info,
      url: this.props.endpointPostData,
      callback: (e) => this.setApiData(e.responseText, form),
      errorCallback: (e) => this.errorSubmit(e, form),
    });
  },

  setApiData: function (responseText, form) {
    const data = JSON.parse(responseText);
    this.props.data = data;

    if (data.currencyUser) {
      this.props.inputs.currency.value = data.currencyUser;
    }
    if (data.language) {
      this.props.inputs.language.value = data.language;
      this.updateCountryFlag(data.language, form);
    }

    if (data.preferences) {
      this.props.preferencesData = data.preferences;
      form.querySelectorAll(this.personalElements.preferenceItem).forEach((item) => {
        item.classList.toggle("mod--active", !!data.preferences[item.dataset.value]);
      });
    }
  },

  updateCountryFlag: function (langValue, element) {
    const countryCodes = { ar: "sa", cs: "cz" };
    const countryCode = countryCodes[langValue] || langValue.slice(-2);

    const flagContainer = element.querySelector(this.personalElements.countryFlag);
    flagContainer.innerHTML = `<span class='iti__flag iti__${countryCode.toLowerCase()}'></span>`;
  },

  setInputs: function (form) {
    this.props.inputs.form = form;
    this.props.inputs.language = form.querySelector(this.personalElements.language);
    this.props.inputs.currency = form.querySelector(this.personalElements.currency);
    this.props.inputs.preferences = form.querySelector(this.personalElements.preferences);

    this.getApiData(form);
  },

  successSubmit: function (e, form) {
    this.toggleModal(form, this.modals.successModal);
  },

  errorSubmit: function (e, form) {
    this.toggleModal(form, this.modals.errorModal);
  },

  toggleModal: function (form, modalSelector) {
    const modal = form.querySelector(modalSelector);
    const closeButton = modal.querySelector(".js-close-modal");

    closeButton.addEventListener("click", () => {
      modal.classList.remove(this.modals.activeClass);
    });

    modal.classList.add(this.modals.activeClass);
  },
};

bcl.c.mybPreferences.preInit();
