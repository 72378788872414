bcl.c.mybLoyaltyTargeting = {
  props: {
    componentSelector: ".myb-loyalty-target-JS",
    targetContentSelector: "div[data-level]",
    hiddenClass: "hidden",
  },

  init: function (userData) {
    this.props.loyaltyLevel = parseInt(userData?.federationId ? userData["myb-level"] : 0);

    document.querySelectorAll(this.props.componentSelector).forEach(($component) => {
      $component.querySelectorAll(this.props.targetContentSelector).forEach(($targetContent) => {
        const targetLevel = parseInt($targetContent.dataset.level);
        if (targetLevel === this.props.loyaltyLevel) {
          $targetContent.classList.remove(this.props.hiddenClass);
        } else {
          $targetContent.remove();
        }
      });
    });
  },
};
