/* eslint-disable camelcase */
bcl.c.mybProfile = {
  init: function (user) {
    if (bcl.profile.login !== "true") {
      return;
    }

    const component = document.querySelector(".c-myb-content") || document.querySelector(".c-myb-profile-status") || document.querySelector(".c-myb-menu");

    if (component) {
      this.getInfo(component, user);
    }
  },

  getInfo: function (component, user) {
    if (!component || bcl.profile.mapValues.data) {
      return;
    }

    this.initialize(user);
  },

  initialize: function (ch_data) {
    if (!ch_data) {
      return;
    }

    const {
      "myb-bookingNumber": bookingNumber,
      "myb-name": name,
      "myb-surname": surname,
      "myb-level": level,
      "myb-identifier": identifier,
      "myb-discount": discount,
      datetime: datetime,
      federationId: federationId,
      "myb-userCreationDate": userCreationDate,
    } = ch_data;

    bcl.profile.mapValues.data = { bookingNumber, name, surname, level, identifier, discount, datetime, federationId, userCreationDate };

    this.callFunctionsComponents();
  },

  callFunctionsComponents: function () {
    bcl.c.mybProfileStatus.init();
    bcl.c.mybContent.init();
    bcl.c.mybMenu.init();
  },
};
