import { List } from "list.js";

bcl.c.mybReservations = {
  props: {
    className: "c-myb-reservations",
    reservations: [],
    mapping: {
      name: { prop: "Hotel.Name" },
      startDate: { prop: "RoomStay.StartDate", format: "Date" },
      endDate: { prop: "RoomStay.EndDate", format: "Date" },
      status: { prop: "status" },
      validity: { prop: "RoomStay.EndDate", format: "Date" },
      discount: { prop: "CancelPolicy.CancelTimeIn" },
      bonus: { prop: "CancelPolicy.CancelTimeIn" },
    },
    valueNames: [],
    data: null,
    list: null,
  },

  init: function () {
    const component = document.querySelector(`.${this.props.className}`);
    if (!component) {
      return;
    }

    this.props.$container = component;

    if (this.props.data) {
      this.initList();
    }
  },

  callback: function (res) {
    this.parseValues(res.response);
    if (this.props.$container) {
      this.initList();
    }
  },

  initList: function () {
    const listContainer = this.props.$container.querySelector(".list");
    if (!listContainer) {
      return;
    }

    const options = {
      valueNames: this.props.valueNames,
      item: this.cleanHTML(listContainer.innerHTML),
      page: 10,
      pagination: {
        innerWindow: 1,
        left: 0,
        right: 0,
        paginationClass: "pagination",
      },
    };

    listContainer.innerHTML = "";

    this.props.list = new List("tableID", options);
    if (this.props.data) {
      this.addData();
    }

    this.initPagination();
  },

  initPagination: function () {
    const paginationContainer = this.props.$container.querySelector(".table-pagination");
    if (!paginationContainer) {
      return;
    }

    this.props.$containerPagination = paginationContainer;

    this.setupPaginationButton("jPaginateNext", 1);
    this.setupPaginationButton("jPaginateBack", -1);
  },

  setupPaginationButton: function (className, offset) {
    const button = this.props.$containerPagination.querySelector(`.${className}`);
    if (!button) {
      return;
    }

    button.addEventListener("click", () => {
      const pages = Array.from(this.props.$containerPagination.querySelectorAll("ul.pagination li"));
      const activeIndex = pages.findIndex((page) => page.classList.contains("active"));
      const targetIndex = activeIndex + offset;

      if (targetIndex >= 0 && targetIndex < pages.length) {
        pages[targetIndex].dispatchEvent(new Event("click"));
      }
    });
  },

  addData: function () {
    this.props.list.add(this.props.data);
  },

  parseValues: function (json) {
    const data = JSON.parse(json),
      reservations = data.reservations,
      results = reservations.map((reservation) => this.extractMappedValues(reservation));

    this.props.data = results;
  },

  extractMappedValues: function (item) {
    const result = {};
    this.props.valueNames = this.props.valueNames.length ? this.props.valueNames : Object.keys(this.props.mapping);

    for (const key of this.props.valueNames) {
      const { prop, format } = this.props.mapping[key];
      let value = bcl.u.getInData(item, prop);

      if (format && typeof this[`format${format}`] === "function") {
        value = this[`format${format}`](value);
      }

      result[key] = value;
    }

    return result;
  },

  formatDate: function (value) {
    return new Date(value).toLocaleDateString();
  },

  cleanHTML: function (html) {
    return html
      .replace(/(?:\r\n|\r|\n)/g, "")
      .replace(/>(\s)+</g, "><")
      .replace(/(\s)+<tr>/g, "<tr>");
  },
};

bcl.u.docReady(bcl.c.mybReservations.init.bind(bcl.c.mybReservations), true);
