bcl.c.mybMenu = {
  props: {
    className: "c-myb-menu",
    textMessage: ".js-text-message",
    navContainer: ".js-myb-menu-nav",
    itemNav: ".js-nav-item",
  },

  levels: [0, 1, 3],

  init: function (baseDom) {
    const baseFind = baseDom || document;
    const $component = baseFind.getElementsByClassName(this.props.className);
    if (!$component || !$component[0]) {
      return;
    }

    this.props.$container = $component;
    this.setHeaderValue();

    Array.from($component).forEach((element) => {
      this.updateInfo(element);
      this.setupNavigation(element);
      this.checkAnchorTabMenu();
      this.checkAnchor();
    });
  },

  setHeaderValue: function () {
    if (document.body.dataset.mode === "true") {
      const textElement = document.querySelector(".js-name-myb");
      const name = bcl.profile.mapValues.data?.name || "";
      if (name) {
        textElement.innerHTML = textElement.innerHTML.replace("[client]", name);
      }
    }
  },

  updateInfo: function (component) {
    const texts = component.querySelectorAll(this.props.textMessage);
    let targetIndex = 0;
    let isMax = false;

    this.levels.forEach((level, index) => {
      if (bcl.profile.mapValues.data.bookingNumber >= level) {
        targetIndex = index;
      }
    });

    if (component.classList.contains("mod--menu-v2")) {
      targetIndex = bcl.profile.mapValues.data.level - 1;
      isMax = targetIndex > 3;
    }

    const displayText = texts[targetIndex];
    if (displayText && !isMax) {
      displayText.classList.remove("mod--hidden");

      const nextLevel = this.levels[targetIndex + 1] || 5;
      const staysRemaining = nextLevel - bcl.profile.mapValues.data.bookingNumber;

      Array.from(displayText.children).forEach((child) => {
        if (child.innerHTML.includes("[name]")) {
          child.innerHTML = child.innerHTML.replace("[name]", bcl.profile.mapValues.data.name || "");
        }
        if (child.innerHTML.includes("[mybLeftStays]") && staysRemaining) {
          child.innerHTML = child.innerHTML.replace("[mybLeftStays]", staysRemaining.toString());
        }
      });
    }
  },

  setupNavigation: function (component) {
    const navContainer = component.querySelector(this.props.navContainer);
    navContainer?.querySelectorAll(this.props.itemNav).forEach((item) => {
      item.addEventListener("click", () => {
        this.checkAnchor(item.href);
        this.checkAnchorTabMenu(item.href);
      });
    });
  },

  checkAnchor: function (href = window.location.href) {
    const key = href.split("#")[1] || "";
    if (!key) {
      return;
    }

    const faq = document.querySelector(".c-faq");
    if (!faq) {
      return;
    }

    const navItems = faq.querySelectorAll(".menu-faq-js");
    const contentBlocks = faq.querySelectorAll(".block-quest-faq-js");

    navItems.forEach((item) => {
      const isActive = item.dataset.anchor === key;
      item.classList.toggle("c-faq__nav-item-active", isActive);
      if (isActive) {
        this.updateFaqContent(contentBlocks, item.dataset.attribute);
      }
    });
  },

  checkAnchorTabMenu: function (href = window.location.href) {
    const key = href.split("#")[1] || "";
    if (!key) {
      return;
    }

    const tabMenu = document.querySelector(".c-tab-menu");
    if (!tabMenu) {
      return;
    }

    const tabItems = tabMenu.querySelectorAll(".menu-tab-menu-js");
    const tabContent = tabMenu.querySelectorAll(".block-quest-tab-menu-js");

    tabItems.forEach((item) => {
      const isActive = item.dataset.anchor === key;
      item.classList.toggle("c-tab-menu__nav-item-active", isActive);
      if (isActive) {
        this.updateFaqContent(tabContent, item.dataset.attribute);
      }
    });
  },

  updateFaqContent: function (contentBlocks, attributeValue) {
    contentBlocks.forEach((content) => {
      const shouldShow = content.dataset.attribute === attributeValue;
      content.classList.toggle("hidden", !shouldShow);
      if (shouldShow) {
        bcl.u.goToWithScroll({
          top: bcl.s.dataAnchor.getScrollTop(content) + bcl.s.dataAnchor.props.offset,
        });
      }
    });
  },
};
