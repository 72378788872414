/* eslint-disable @typescript-eslint/no-use-before-define */
(() => {
  const MybBenefit = () => {
    const selectors = {
      component: ".myb-benefit",
      content: ".js-myb-benefit",
      buttonMore: ".js-myb-benefit-more",
      buttonLess: ".js-myb-benefit-less",
      swiperComponent: ".js-swiper-myb-benefit",
      levels: {
        essential: "1",
        special: "2",
        unique: "3",
      },
    };

    const init = () => {
      const elements = Array.from(document.querySelectorAll(selectors.component));
      if (!elements.length) {
        return;
      }

      const userData = bcl.profile.mapValues.data || {};
      const level = bcl.u.getKeyByValue(selectors.levels, userData.level) || null;

      elements.forEach((element) => {
        const content = element.querySelector(selectors.content);
        if (content) {
          initComponent(content, level);
        }
      });

      moveBenefitSwiperToCurrentLevel(level);
    };

    const initComponent = (content, level) => {
      unlockContentByLevel(content, level);

      const buttonMore = content.querySelector(selectors.buttonMore);
      const buttonLess = content.querySelector(selectors.buttonLess);

      buttonMore?.addEventListener("click", () => toggleFlip(content, true));
      buttonLess?.addEventListener("click", () => toggleFlip(content, false));
    };

    const unlockContentByLevel = (content, level) => {
      const levelClasses = {
        essential: ["mod--essential"],
        special: ["mod--essential", "mod--special"],
        unique: ["mod--essential", "mod--special", "mod--unique"],
      };

      if (levelClasses[level]?.some((cls) => content.classList.contains(cls))) {
        content.classList.add("mod--unlock");
      }
    };

    const toggleFlip = (content, isFlipped) => {
      content.classList.toggle("mod--flip", isFlipped);
      content.classList.toggle("mod--reverse-flip", !isFlipped);
    };

    const moveBenefitSwiperToCurrentLevel = (level) => {
      document.querySelectorAll(selectors.swiperComponent).forEach((carousel) => {
        const allSlides = Array.from(carousel.querySelectorAll(selectors.content));
        const targetSlideIndex = allSlides.findIndex((slide) => slide.classList.contains(`mod--${level}`));

        if (targetSlideIndex !== -1) {
          carousel.swiper.slideTo(targetSlideIndex);
        }
      });
    };

    init();
  };

  window.addEventListener("load", MybBenefit);
})();
