(() => {
  const MyBDetails = () => {
    const selectors = {
      component: "myb-details",
      swiper: ".swiper",
    };

    const swiperConfig = {
      slidesPerView: 1.2,
      freeMode: false,
      loop: false,
      spaceBetween: 30,
      centeredSlides: false,
    };

    const initSwiper = (element) => {
      const swiperElement = element.querySelector(selectors.swiper);
      if (swiperElement && window.innerWidth < 1025) {
        if (!swiperElement.swiper) {
          new window.Swiper(swiperElement, swiperConfig);
        }
      } else if (swiperElement?.swiper) {
        swiperElement.swiper.destroy(true, true);
      }
    };

    const init = () => {
      const parentElements = Array.from(document.getElementsByClassName(selectors.component));
      parentElements.forEach((element) => {
        initSwiper(element);
      });

      window.addEventListener("resize", () => {
        parentElements.forEach((element) => initSwiper(element));
      });
    };

    init();
  };

  document.addEventListener("DOMContentLoaded", MyBDetails);
})();
